import React from 'react';
import './links.css';
import OEFE from '../../assets/OEFE.jpg';
import labora from '../../assets/labora.jpg';
import edu4schools from '../../assets/edu4schools.png';
import ypourgeio from '../../assets/ypourgeio.jpg';

const Links = () => {
  return (
      <div className="anodos__links-container">
        <div className="anodos__links-container_box">
          <a href='https://www.oefe.gr/' target="_blank" rel="noopener noreferrer">
            <img src={OEFE} alt="OEFE"></img>
          </a>
        </div>
        <div className="anodos__links-container_box">
          <a href='https://labora.gr/' target="_blank" rel="noopener noreferrer">
            <img src={labora} alt="labora"></img>
          </a>
        </div>
        <div className="anodos__links-container_box">
          <a href='https://www.edu4schools.gr/' target="_blank" rel="noopener noreferrer">
            <img src={edu4schools} alt="edu4schools"></img>
          </a>
        </div>
        <div className="anodos__links-container_box">
          <a href='https://www.minedu.gov.gr/' target="_blank" rel="noopener noreferrer">
            <img src={ypourgeio} alt="ypourgeio"></img>
          </a>
        </div>
      </div>
  )
}

export default Links