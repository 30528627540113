import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import { Carousel } from './Carousel';
import './index.css';

const Mathites = () => {
  return (
    <div className="Mathites>">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="body">
          <Carousel />
        </div>
        <Footer />
    </div>
  )
}

export default Mathites