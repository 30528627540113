import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './I-Leitourgia-Mas.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';


const Leitourgia = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="Leitourgia">
        <div className="gradient__bg">
          <Navbar className="navbar"/>
        </div>
        <div className="Leitourgia__main-container">
          <div className="Leitourgia__container">
            <div className="Leitourgia__container-h">
              <h1>Η Λειτουργία μας</h1>
            </div>
            <div className="Leitourgia__container-p">
              <p>&nbsp;&nbsp;&nbsp;Φροντιστήριο «Άνοδος» σημαίνει προοπτική στο μέλλον, ανταγωνιστική ποιότητα στην διδασκαλία των μαθημάτων, απόλυτη εξειδίκευση σε μαθήματα ειδικής βαρύτητας (όπως είναι τα Μαθηματικά, η Φυσική, η Χημεία, τα Αρχαία, η Έκθεση, η Βιολογία κτλ) που εξασφαλίζουν το εισιτήριο εισόδου στη σχολή που επιθυμεί ο υποψήφιος.</p>
              <p>&nbsp;&nbsp;&nbsp;Ποιότητα στην διδασκαλία των μαθημάτων, εννοείται με βάση:</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>1.</span> Την διδασκαλία του μαθήματος μέσα στις τάξεις από απόλυτα εξειδικευμένους στο αντικείμενο, καθηγητές. </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>2.</span> Το χρονοδιάγραμμα της διεκπεραίωσης της εξεταστέας ύλης μέσα σε τακτά χρονικά διαστήματα ώστε να περισσεύει αρκετά ωφέλιμος χρόνος, για διδασκαλία και ανάπτυξη συνδυαστικών θεμάτων σύμφωνα µε το πνεύμα των εισιτηρίων εξετάσεων.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>3.</span> Τη διενέργεια σε όλη την διάρκεια της χρονιάς προκαθορισμένων εβδομαδιαίων διαγωνισμάτων, μια παροχή που συνηθίζει τον υποψήφιο στις κανονικές συνθήκες των τριών ωρών και των συνδυαστικών θεμάτων που θα βιώσει στις πανελλαδικές εξετάσεις.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>4.</span> Την διενέργεια των επαναληπτικών διαγωνισμάτων της ΟΕΦΕ κάθε Δεκέμβρη και Απρίλη που είναι στάδιο προετοιμασίας τόσο της τεχνικής όσο και της ψυχολογικής στήριξης των μαθητών για τις εξετάσεις τους.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>5.</span> Την παροχή επιπρόσθετης υποστήριξης ολοκληρωμένων βοηθημάτων, όπως φωτοτυπίες, σημειώσεις και φροντιστηριακά βιβλία γραμμένα σε απλό ύφος µε μεγάλη ποικιλία ασκήσεων και μεθοδολογιών που μαθαίνει τον μαθητή να σκέφτεται και να ελίσσεται ανάλογα την περίπτωση και να θωρακίζεται από τυχόν παραλείψεις που δεν έχουν καλυφθεί στο παρελθόν από τον ίδιο τον μαθητή.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>6.</span> Την διανομή σε όλη την διάρκεια της χρονιάς στατιστικά έντυπα µε τις βάσεις εισαγωγής σε κάθε σχολή, συγκριτικούς πίνακες ανά μάθημα, ώστε ο υποψήφιος να γνωρίζει τις αυξομειώσεις των βάσεων, της Ελάχιστης Βάσης Εισαγωγής και των Συντελεστών Βαρύτητας ανά σχολή.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>7.</span> Την οργάνωση ολοκληρωμένων Σεμιναρίων Επαγγελματικού Προσανατολισμού κάθε χρόνο όπου συμμετέχουν και οι γονείς των παιδιών, στα οποία καλούνται ειδικευόμενοι ακαδημαϊκοί καθηγητές και ψυχολόγοι σε θέματα παιδείας ώστε να ξεκαθαρίζεται σωστά το αντικείμενο σπουδών της κάθε σχολής και να αξιολογούνται οι σχολές πρώτης ζήτησης και άμεσης επαγγελματικής αποκατάστασης στο σύγχρονο ευρωπαϊκό περιβάλλον. Τονίζονται τα δυναμικά επαγγέλματα του μέλλοντος όπως και τα επαγγέλματα εκείνα που σήμερα οδηγούν µε μαθηματική ακρίβεια στην ανεργία για να διαλέξει σωστά ο κάθε υποψήφιος και η οικογένειά του το επάγγελμα του.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>8.</span> Στην καθοριστική περίοδο Ιουνίου - Ιουλίου η φροντιστηριακή μονάδα είναι ανοικτή σε καθημερινή βάση, βοηθά τους υποψήφιους και τους στηρίζει, τόσο διδακτικά όσο και ψυχολογικά σε όλη την διάρκεια των εξετάσεων. Μετά το πέρας των Πανελλαδικών εξετάσεων διενεργείται Σεμινάριο Συμπλήρωσης Μηχανογραφικού Δελτίου, δίνοντας σαφείς οδηγίες και οδηγούς σπουδών στους υποψήφιους και στους γονείς τους, για την σωστή συμπλήρωσή του. </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>9.</span> Τη χρήση πλατφόρμας τεχνολογικής υποστήριξης (edu4schools) όπου παρέχει την δυνατότητα ελέγχου επίδοσης του μαθητή. Ο μαθητής μπορεί να βρει διαδικτυακά διαγωνίσματα σε κάθε μάθημα και σε κάθε κεφάλαιο που τον ελέγχουν και τον αξιολογούν αυτομάτως. Έτσι, του παρέχεται η δυνατότητα οποιαδήποτε στιγμή και ώρα να ελέγξει από το σπίτι του, τις γνώσεις του.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>10.</span> Την διαμόρφωση συστήματος διαχείρισης ποιότητας των παρεχόμενων εκπαιδευτικών υπηρεσιών (το γνωστό μας ISO9001) που προσφέρει υπεύθυνα, πιστοποίηση στην εκπαίδευση.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='span1'>11.</span> Τη χρήση σύγχρονων εποπτικών μέσων διδασκαλίας. Επιλέγοντας τον  διαδραστικό πίνακα κατά την εκπαιδευτική διαδικασία αυξάνεται στο μέγιστο η διαδραστικότητα στη μάθηση. Οι διαδραστικοί πίνακες ενσωματώνουν λειτουργίες προβολής και αλληλεπίδρασης και έτσι καλύπτουν τις ανάγκες των μαθητών είτε είναι οπτικοί είτε είναι κινητικοί τύποι. Οι μαθητές με μαθησιακές δυσκολίες (διάσπαση προσοχής, δυσλεξία, υπερκινητικότητα) μπορούν να δουν, να διαβάσουν και να χειριστούν πληροφορίες με μεγαλύτερη ευκολία.</p>
            </div>
          </div>
          <div className="Leitourgia__side-Containers">
            <div className="Leitourgia__side-Container1">
              <h1>Χρήσιμες Σελίδες</h1>
              <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="anodos__alykeiou-email-container">
              <form className="anodos__alykeiou-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__alykeiou-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__alykeiou-email-container_label">Όνομα</label>
                <input className="anodos__alykeiou-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__alykeiou-email-container_label">Email</label>
                <input className="anodos__alykeiou-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__alykeiou-email-container_label">Μήνυμα</label>
                <textarea className="anodos__alykeiou-email-container_input-message" name="message" required/>
                <input className="anodos__alykeiou-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Alykeiou-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Leitourgia