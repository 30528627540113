import React from 'react';
import { Footer } from '../../containers';
import { Navbar } from '../../components';
import './To-Frontistirio.css';

const Frontistirio = () => {
  return (
    <div className="Frontistirio">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="Frontistirio__container">
          <div className="Frontistirio__header">
            <h1>Το Φροντιστήριο</h1>
          </div>
          <div className="Frontistirio__cards">
              <a href='./Mathodos-Ekpaideusis' className="Frontistirio__card">
                <h3>Μέθοδος εκπαίδευσης</h3>
              </a>
              <a href='./Oi-Xwroi-Mas' className="Frontistirio__card">
                <h3>Οι χώροι μας</h3>
              </a>
              <a href='./Oi-Ekdolwseis-mas' className="Frontistirio__card">
                <h3>Οι εκδηλώσεις μας</h3>
              </a>
              <a href='./Paroxes-Ypiresiwn' className="Frontistirio__card">
                <h3>Παροχές υπηρεσιών</h3>
              </a>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Frontistirio;
