import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Paroxes-Ypiresiwn.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';


const Paroxes = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="Paroxes">
        <div className="gradient__bg">
          <Navbar className="navbar" />
        </div>
        <div className="Paroxes__main-container">
          <div className="Paroxes__container">
            <div className="Paroxes__container-h">
              <h1>ΠΑΡΟΧΕΣ ΥΠΗΡΕΣΙΩΝ</h1>
            </div>
            <div className="Paroxes__container-p">
            <p>&nbsp;&nbsp;&nbsp;Το φροντιστήριο από το 1999 δραστηριοποιείται στον τομέα της παροχής εκπαιδευτικών υπηρεσιών σε φροντιστηριακό επίπεδο για την Δευτεροβάθμια εκπαίδευση.</p>
                  <p>&nbsp;&nbsp;&nbsp;Διδάσκονται μαθήματα Μέσης Εκπαίδευσης σε μαθητές Γυμνασίου και Α', Β' , Γ’ Λυκείου και ΕΠΑ.Λ. καθώς τους προετοιμάζει για τις πανελλαδικές εξετάσεις. Τα μαθήματα που διδάσκονται είναι:
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Μαθηματικά Προσανατολισμού,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Έκθεση & Λογοτεχνία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Φυσική-Χημεία Προσανατολισμού,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Βιολογία Προσανατολισμού,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Αρχές Οικονομικής Θεωρίας (Οικονομία),</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Ανάπτυξη Εφαρμογών σε Προγραμματιστικό Περιβάλλον (Α.Ε.Π.Π.),</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Αρχαία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Λατινικά,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Ιστορία ,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Άλγεβρα & Γεωμετρία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Φυσική-Χημεία γενικής παιδείας,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; ΑΟΔΕ (Αρχές Οργάνωσης και Διοίκησης Επιχειρήσεων),</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Υγιεινή & Ανατομία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Δίκτυα & Προγραμματισμός,</p>
            </p>
              <p>&nbsp;&nbsp;&nbsp;Παρέχουμε:
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Ομοιογενή τμήματα</p> 
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συνεχής επικοινωνία με μαθητές και γονείς</p> 
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Εκδηλώσεις επαγγελματικού προσανατολισμού</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Προγραμματισμένα  διαγωνίσματα</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συχνά test κατά την εκτίμηση του υπεύθυνου καθηγητή</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Test Επαγγελματικού Προσανατολισμού</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Επαναληπτικά διαγωνίσματα της ΟΕΦΕ</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Πλούσιο Εκπαιδευτικό Υλικό</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Σύγχρονοι μέθοδοι διδασκαλίας μέσω διαδραστικών πινάκων</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Χρήση Πληροφοριακών συστημάτων (Έπαφος)</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Υπολογισμός επιδόσεων των μαθητών μας και επαναξιολόγηση των αρχικών τους στόχων</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Προσομοίωση συμπλήρωσης μηχανογραφικού δελτίου</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συμπληρωματικές δραστηριότητες της εκπαιδευτικής διαδικασίας</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Διανομή ενημερωτικών φυλλαδίων (σχολές, βάσεις, κτλ)</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Εκδηλώσεις εκπαιδευτικού και ψυχολογικού χαρακτήρα</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Καθοδήγηση για τη συμπλήρωση των αιτήσεων σύμφωνα με τις  προκηρύξεις των ένστολων σχολών</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Καθοδήγηση για την τελική συμπλήρωση του μηχανογραφικού δελτίου</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Παροχή συγγραμμάτων</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συνεργασία με ψυχολόγο και λογοθεραπευτή</p>
              </p>
            </div>
          </div>
          <div className="Paroxes__side-Containers">
            <div className="Paroxes__side-Container1">
              <h1>Χρήσιμες Σελίδες</h1>
              <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="anodos__alykeiou-email-container">
              <form className="anodos__alykeiou-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__alykeiou-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__alykeiou-email-container_label">Όνομα</label>
                <input className="anodos__alykeiou-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__alykeiou-email-container_label">Email</label>
                <input className="anodos__alykeiou-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__alykeiou-email-container_label">Μήνυμα</label>
                <textarea className="anodos__alykeiou-email-container_input-message" name="message" required/>
                <input className="anodos__alykeiou-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Alykeiou-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Paroxes