import React from 'react';

import { Footer } from '../../containers';
import { Navbar, Email} from '../../components';
import './contact.css';

const Contact = () => {
  return (
    <div className="Contact">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <Email />
        <Footer />
    </div>
  )
}

export default Contact