import React, { useState } from "react";
import { CarouselItem } from "./CarouselItem";
export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    {
      title: "Μαρία Μιχαηλίδου, Παιδαγωγικό 2020",
      description:
        "«Επέλεξα το φροντιστήριο Άνοδος και είμαι πολύ ευχαριστημένη με την ποιότητα εκπαίδευσης που μου παρείχε και στις 3 τάξεις του λυκείου. Οι καθηγητές ήταν πραγματικά εξαιρετικοί με πλούσια μεταδοτικότητα και οργάνωση. Επιπλέον μας παρείχαν εκπαιδευτικό υλικό το οποίο αποδείχτηκε πολύ σημαντικό εργαλείο για την επιτυχία των στόχων μας. Πέρα από την τυπική σχέση εκπαιδευτικού και μαθητή όμως αυτά τα χρόνια μας υποστήριζαν και μας ενθάρρυναν με κάθε πιθανό τρόπο. Χτίσαμε μια ιδιαίτερη σχέση αγάπης και κατανόησης, πήραμε πολλά πράγματα από αυτούς τους ανθρώπους και τους είμαι πραγματικά ευγνώμων.»",
      icon: require("./Media/undraw_brainstorming_re_1lmw.svg"),
    },
    {
      title: "Σωτηρία Σώμου, Αγωγής και φροντίδας στη πρώιμη Παιδική Ηλικία 2020",
      description:
        "«Επέλεξα το φροντιστήριο Άνοδος και νιώθω πολύ ικανοποιημένη από αυτή την επιλογή μου, καθώς όλοι οι εκπαιδευτικοί ήταν πάντα εκεί στην προσπάθεια να καλυφθούν όλα τα μαθησιακά κενά που υπήρχαν από προηγούμενα χρόνια, με ψυχολογική στήριξη και υποστηρικτικό  τρόπο, με απώτερο σκοπό να εκπληρώσω τον στόχο μου.»",
      icon: require("./Media/undraw_brainstorming_re_1lmw.svg"),
    },
    {
      title: "Σαμπουγκασίδου Ρένα, Μαθηματικό 2020",
      description:
        "«Το φροντιστήριο Άνοδος  για μένα ήταν η ιδανική επιλογή. Με έφτασε στην ζωή σου πανεπιστημίου μέσα από πνευματική και ψυχική καλλιέργεια. Πρόκειται για ένα περιβάλλον με αλληλοκατανόηση, ομαδικό και μεθοδικό πνεύμα που με στήριξε καθ’ όλη την πορεία.»",
      icon: require("./Media/undraw_brainstorming_re_1lmw.svg"),
    },
    {
      title: "Γιώργος Αβράμης, Πληροφορική 2020",
      description:
        "«Ξεκίνησα με το φροντιστήριο Άνοδος από την δευτέρα λυκείου και συνέχισα την επόμενη χρονιά για τα μαθήματα των πανελλαδικών. Η επίδοση μου δεν θα ήταν πουθενά κοντά σε αυτήν που είχα στις εξετάσεις αν δεν πήγαινα στο φροντιστήριο, καθώς εκεί είχα δίπλα μου καθηγητές που ήταν πολύ ενθαρρυντικοί και γεμάτοι εμπειρία που βοηθούσε ψυχολογικά σε θέματα όπως στην διαχείριση του άγχους αλλά και στον τρόπο σκέψης γενικότερα. Γενικά ήταν ένα περιβάλλον που ήταν γεμάτο χαρά και ενέργεια αλλά και σοβαρότητα εκεί που έπρεπε.»",
      icon: require("./Media/undraw_brainstorming_re_1lmw.svg"),
    },
    {
      title: "Ευγενία Δήμου, Φυσικής - 2018",
      description:
        "«Το φροντιστήριο Άνοδος στάθηκε δίπλα μου και με την πολυετή εμπειρία του στις πανελλαδικές εξετάσεις με βοήθησε να χαράξω το μονοπάτι της επιτυχίας μου σε αυτές. Οι καθηγητές ήταν πάντα παρόντες όταν τους χρειαζόμουν και δούλευαν ως μια ενεργή ομάδα, δημιουργώντας ένα οικείο κλίμα το οποίο με βοήθησε να περάσω στην σχολή που επιθυμούσα.»",
      icon: require("./Media/undraw_brainstorming_re_1lmw.svg"),
    },
  ];
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    setActiveIndex(newIndex);
  };
  return (
    <div className="carousel">
      <div
        className="inner"
        style={{ transform: `translate(-${activeIndex * 100}%)`
     }}
      >
        {items.map((item) => {
          return <CarouselItem item={item} width={"100%"} />;
        })}
      </div>

      <div className="carousel-buttons">
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <span class="material-symbols-outlined">arrow_back_ios</span>{" "}
        </button>
        <div className="indicators">
          {items.map((item, index) => {
            return (
              <button
                className="indicator-buttons"
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <span
                  className={`material-symbols-outlined ${
                    index === activeIndex
                      ? "indicator-symbol-active"
                      : "indicator-symbol"
                  }`}
                >
                  radio_button_checked
                </span>
              </button>
            );
          })}
        </div>
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <span class="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </div>
  );
};