import React from 'react';

import { Footer } from '../../containers';
import { Navbar, Email} from '../../components';
import './Themata.css';

const Themata = () => {
  return (
    <div className="Themata">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <Email />
        <Footer />
    </div>
  )
}

export default Themata