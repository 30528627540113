import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './EPAL.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';

const EPAL = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="EPAL">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="EPAL__main-container">
          <div className="EPAL__container">
            <div className="EPAL__container-h">
              <h1>Γ’ ΕΠΑΓΓΕΛΜΑΤΙΚΟΥ ΛΥΚΕΙΟΥ</h1>
              <div className="EPAL__container-p">
                <p>Ο μαθητής της Γ’ Επαγγελματικού Λυκείου παρακολουθεί μαθήματα σύμφωνα με το πρόγραμμα σπουδών που επέλεξε.</p>
                <p> Ωστόσο, του δίνεται η δυνατότητα του Επαγγελματικού προσανατολισμού, σε περίπτωση που δεν έχει επιλέξει έγκαιρα την κατεύθυνσή του, μια παροχή που θα τον οδηγήσει πιο κοντά στον στόχο του.</p>
              </div>
              <div className="EPAL__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΤΟΜΕΑΣ ΠΛΗΡΟΦΟΡΙΚΗΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΔΙΚΤΥΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>8 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΤΟΜΕΑΣ ΟΙΚΟΝΟΜΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΕΚΘΕΣΗ ΚΑΙ ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΑΡΧΕΣ ΟΙΚΟΝΟΜΙΚΗΣ ΘΕΩΡΙΑΣ Α.Ο.Θ.</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΑΡΧΕΣ ΟΡΓΑΝΩΣΗΣ & ΔΙΟΙΚΗΣΗΣ Α.Ο.Δ.Ε.</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>10 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΤΟΜΕΑΣ ΥΓΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΕΚΘΕΣΗ ΚΑΙ ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΑΝΑΤΟΜΙΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΥΓΙΕΙΝΗ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>8 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΓΕΝΙΚΗΣ ΠΑΙΔΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>6 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="EPAL__container-h1">
              <h1>Είσαι μαθητής της Β’ Επαγγελματικού ΛΥΚΕΙΟΥ και ενδιαφέρεσαι για Γ ΕΠΑΛ;</h1>
            </div>
            <div className="EPAL__container-a">
              <a href='../Mathimata'>Έναρξη θερινής προετοιμασίας από 18 Αυγούστου.</a>
            </div>
            <div className="EPAL__container-h">
              <h1>Β’ Επαγγελματικού ΛΥΚΕΙΟΥ</h1>
              <div className="EPAL__container-table_B">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΓΕΝΙΚΗΣ ΠΑΙΔΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΑΛΓΕΒΡΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <div className="EPAL__container-p">
                <p>ΕΠΙΠΛΕΟΝ ΔΙΔΑΣΚΟΝΤΑΙ :</p>
              </div>
              <div className="EPAL__container-table_B">
                <table>
                  <thead>
                  </thead>
                  <tbody>
                      <tr>
                        <td>ΦΥΣΙΚΗ & ΧΗΜΕΙΑ ΓΕΝΙΚΗΣ ΠΑΙΔΕΙΑΣ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΑΡΧΕΣ ΛΟΓΙΣΤΙΚΗΣ (ΤΟΜΕΑΣ ΟΙΚΟΝΟΜΙΑΣ)</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΟΙΚΟΝΟΜΙΚΑ ΜΑΘΗΜΑΤΙΚΑ (ΤΟΜΕΑΣ ΟΙΚΟΝΟΜΙΑΣ)</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΑΝΑΤΟΜΙΑ-ΦΥΣΙΟΛΟΓΙΑ  (ΤΟΜΕΑΣ ΥΓΕΙΑΣ)</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΑΡΧΕΣ ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΥ ΥΠΟΛΟΓΙΣΤΩΝ (ΤΟΜΕΑΣ ΠΛΗΡΟΦΟΡΙΚΗΣ)</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="EPAL__side-Containers">
              <div className="EPAL__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
              <div className="anodos__epal-email-container">
              <form className="anodos__epal-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__epal-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__epal-email-container_label">Όνομα</label>
                <input className="anodos__epal-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__epal-email-container_label">Email</label>
                <input className="anodos__epal-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__epal-email-container_label">Μήνυμα</label>
                <textarea className="anodos__epal-email-container_input-message" name="message" required/>
                <input className="anodos__epal-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__epal-email-container_popup anodos__epal-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__epal-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__epal-email-container_popup anodos__epal-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__epal-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__EPAL-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__EPAL-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__EPAL-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__EPAL-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__EPAL-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default EPAL