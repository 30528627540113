import React from 'react';

import { Footer } from '../../containers';
import { Navbar, Email} from '../../components';
import './Trapeza.css';

const Trapeza = () => {
  return (
    <div className="Trapeza">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <Email />
        <Footer />
    </div>
  )
}

export default Trapeza