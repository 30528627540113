import React from 'react';
import './main.css';
import { Important } from '../../components'
import { main01, main02, main03, main04, main05} from './imports';

const Main = () => {
  return (
    <div className="anodos__main section__padding">
      <div className="anodos__main-header">
        <h1>Σημαντικοί Σύνδεσμοι</h1>
      </div>
      <div className="anodos__main-container">
        <div className="anodos__main-container_groupA">
          <Important imgUrl={main01} title="Θέματα Πανελλαδικών - Διδακτέα Ύλη" par="Θέματα 2010-2022" link="/Themata"/>
        </div>
        <div className="anodos__main-container_groupB">
          <Important imgUrl={main02} title="Υπολογισμός Μορίων" par="Γρήγορος υπολογισμός μορίων" link="/Ypologismos"/>
          <Important imgUrl={main03} title="Σπουδές και Επαγγέλματα" par="Link σχολών & κατευθυνσεις" link="/Spoudes"/>
          <Important imgUrl={main04} title="Τράπεζα Θεμάτων" par="Ασκήσεις" link="/Trapeza"/>
          <Important imgUrl={main05} title="e-εκπαίδευση" par="Online Μαθήματα και ασκήσεις" link="/eLearning"/>
        </div>
      </div>
    </div>
  )
}

export default Main