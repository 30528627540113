import React from 'react';
import { Footer } from '../../containers';
import { Navbar } from '../../components';
import './PoioiEimaste.css';

const PoioiEimaste = () => {
  return (
    <div className="PoioiEimaste">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="PoioiEimaste__container">
          <div className="PoioiEimaste__header">
            <h1>Ποιοί Είμαστε</h1>
          </div>
          <div className="PoioiEimaste__cards">
              <a href='./i-Filosofia-mas' className="PoioiEimaste__card">
                <h3>Η φιλοσοφία μας</h3>
              </a>
              <a href='./I-Leitourgia-mas' className="PoioiEimaste__card">
                <h3>Η λειτουργία μας</h3>
              </a>
              <a href='./I-Istoria-mas' className="PoioiEimaste__card">
                <h3>Η ιστορία μας</h3>
              </a>
              <a href='./Oi-Kathigites-mas' className="PoioiEimaste__card">
                <h3>Οι καθηγητές μας</h3>
              </a>
              <a href='./Oi-Mathites-mas' className="PoioiEimaste__card">
                <h3>Οι μαθητές μας</h3>
              </a>
          </div>
            <div className='PoioiEimaste__container-p'>
              <p>Με βάση το σχεδιασμό των προγραμμάτων σπουδών του Υπουργείου Παιδείας κάθε μαθητής καλείται να επιλέξει Ομάδα Μαθημάτων Προσανατολισμού στην Β’ και στην Γ’ τάξη την Κατεύθυνση Μαθημάτων η οποία αντιστοιχεί στην ΟΜΠ της επιλογής του.</p>
              <p>Οι επιλογές του θα πρέπει να συμβαδίζουν με τα απαραίτητα κριτήρια εισδοχής των κλάδων σπουδών, στα Ανώτερα και Ανώτατα Εκπαιδευτικά Ιδρύματα (ΑΑΕΙ) της Ελλάδας, καθώς κι άλλων κρατών του εξωτερικού, και τους οποίους θα ήθελε να ακολουθήσει μετά την ολοκλήρωση της φοίτησής του στη Μέση Εκπαίδευση. Ως αποτέλεσμα θα πρέπει να ερευνήσει τη συσχέτιση της κάθε Εκπαιδευτικής επιλογής στο Λύκειο με τα πλαίσια πρόσβασης τα οποία περιλαμβάνουν τους διάφορους κλάδους σπουδών των ΑΑΕΙ.</p>
              <p>Για να διαμορφώσει το πρόγραμμα φοίτησής του θα πρέπει, επίσης, να ερευνήσει τις διάφορες προσφερόμενες επιλογές και συνδυασμούς Μαθημάτων Προσανατολισμού και να επιλέξει την Κατεύθυνση που θα βοηθήσει να αξιοποιήσει τα ενδιαφέροντα, τις ικανότητες σε συνδυασμό με τους προσωπικούς, εκπαιδευτικούς και επαγγελματικούς του στόχους.</p>
              <p>Έτσι, το σχεδιάγραμμα που ακολουθεί είναι προσαρμοσμένο στην πορεία που μπορεί να ακολουθήσει ο μαθητής μας ανάλογα με την επιλογή που θα κάνει και επιλέγοντας κάθε κατεύθυνση μαθημάτων, μπορεί με τη βοήθεια του Φροντιστηρίου Άνοδος να διερευνήσει τις προσφερόμενες επιλογές στα ΑΑΕΙ.</p>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default PoioiEimaste;
