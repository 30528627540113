import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './B-Lykeiou.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';




const BLykeiou = () => {
  
    const form = useRef();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
            e.target.reset();
            setShowSuccess(true);
            setShowError(false);
        }, (error) => {
            console.log(error.text);
            setShowSuccess(false);
            setShowError(true);
        });
    };
  
    const closePopup = () => {
      setShowSuccess(false);
      setShowError(false);
    };
  
  return (
    <div className="BLykeiou">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="BLykeiou__main-container">
          <div className="BLykeiou__container">
            <div className="BLykeiou__container-h">
              <h1>Β’ ΛΥΚΕΙΟΥ</h1>
              <div className="BLykeiou__container-p">
                <p>Ο μαθητής της Β’ Λυκείου παρακολουθεί μαθήματα σύμφωνα με το πρόγραμμα σπουδών που προτίθεται να επιλέξει στην Γ’ Λυκείου. Συγκεκριμένα, το πρόγραμμα σπουδών που ενδείκνυται να ακολουθήσει καλύπτει τόσο τις ανάγκες για τα μαθήματα της Β’ Λυκείου όσο και τις βάσεις που απαιτούνται για την Γ’ τάξη.</p>
                <p> Ωστόσο, του δίνεται η δυνατότητα του Επαγγελματικού προσανατολισμού, σε περίπτωση που δεν έχει επιλέξει έγκαιρα την κατεύθυνσή του, μια παροχή που θα τον οδηγήσει πιο κοντά στον στόχο του.</p>
              </div>
              <div className="BLykeiou__container-h">
                <h2>Ωρολόγιο πρόγραμμα Β΄ Λυκείου:</h2>
              </div>
              <div className="BLykeiou__container-table">
              <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΜΑΘΗΜΑΤΑ ΓΕΝΙΚΗΣ ΠΑΙΔΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>ΑΛΓΕΒΡΑ - ΓΕΩΜΕΤΡΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΧΗΜΕΙΑ ΓΕΝΙΚΗΣ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΦΥΣΙΚΗ ΓΕΝΙΚΗΣ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΕΚΘΕΣΗ - ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΒΙΟΛΟΓΙΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <div className="BLykeiou__container-h">
                <h2>ΕΠΙΠΛΕΟΝ, ΜΑΘΗΜΑΤΑ ΑΝΑ ΕΠΙΣΤΗΜΟΝΙΚΟ ΠΕΔΙΟ</h2>
              </div>
              <div className="BLykeiou__container-table">
              <table>
                  <thead>
                    <tr>
                      <th colSpan="2" class="right-border">1. ΑΝΘΡΩΠΙΣΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                      <th colSpan="2">2. ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>ΑΡΧΑΙΑ</td>
                        <td class="right-border">4 ώρες/ εβδομάδα</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΛΑΤΙΝΙΚΑ</td>
                        <td class="right-border">1 ώρα/ εβδομάδα</td>
                        <td>ΦΥΣΙΚΗ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="right-border"></td>
                        <td>ΧΗΜΕΙΑ Γ' ΛΥΚΕΙΟΥ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="BLykeiou__side-Containers">
              <div className="BLykeiou__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
              <div className="anodos__blykeiou-email-container">
              <form className="anodos__blykeiou-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__blykeiou-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__blykeiou-email-container_label">Όνομα</label>
                <input className="anodos__blykeiou-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__blykeiou-email-container_label">Email</label>
                <input className="anodos__blykeiou-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__blykeiou-email-container_label">Μήνυμα</label>
                <textarea className="anodos__blykeiou-email-container_input-message" name="message" required/>
                <input className="anodos__blykeiou-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__blykeiou-email-container_popup anodos__blykeiou-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__blykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__blykeiou-email-container_popup anodos__blykeiou-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__blykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Alukeiou-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default BLykeiou