import React from 'react';
import './important.css';

const Important = ({ imgUrl ,title, par ,link }) => {
  return (
    <div className="anodos__main-container_important">
      <div className="anodos__main-container_important-image">
        <a href={link}> <img src={imgUrl} alt="main"></img> </a>
      </div>
      <div className="anodos__main-container_important-content">
        <div>
          <a href={link}>
            <h3>{title}</h3>
            <p>{par}</p>
          </a>
        </div>
        <a href={link}>
          <p class="anodos__main-container_important-btn">Διαβάστε Περισσότερα</p>
        </a>
      </div>
    </div>
  )
}

export default Important