import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Proetimasia-G-Lykeiou.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';


const Proetimasia = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="Proetimasia">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="Proetimasia__main-container">
          <div className="Proetimasia__container">
            <div className="Proetimasia__container-h">
              <h1>ΘΕΡΙΝΗ ΠΡΟΕΤΟΙΜΑΣΙΑ ΠΑΝΕΛΛΗΝΙΩΝ</h1>
              <div className="Proetimasia__container-p">
                <p>Γνωρίζοντας τις πολλαπλές απαιτήσεις των δύο τελευταίων τάξεων και αξιοποιώντας την πολυετή εμπειρία μας δημιουργήσαμε ένα Διετές Πρόγραμμα Σπουδών, ώστε ο μαθητής μας:</p>
                <p>Να καλύψει όλα τα γνωστικά κενά των προηγούμενων τάξεων ώστε να διασφαλίσει την επιτυχία του.</p>
                <p>Να ανταποκριθεί με επιτυχία στις απαιτήσεις της τάξης και να πετύχει ΑΡΙΣΤΕΣ επιδόσεις.</p>
                <p>Να αποκτήσει την απαραίτητη υποδομή, ώστε να αντιμετωπίσει με μεγαλύτερη άνεση τα μαθήματα της Γ΄ Λυκείου.</p>
                <p>Να επιτύχει ομαλή μετάβαση στη Γ΄ Λυκείου, ώστε να πετύχει τον στόχο του στις Πανελλαδικές εξετάσεις.</p>
              </div>
              <div className="Proetimasia__container-p2">
                <p>Ο στόχος μας είναι : </p>
                <p>&bull; η πλήρης και χρονικά εμπρόθεσμη κάλυψη της ύλης.</p>
                <p>&bull; να διαπιστωθούν έγκαιρα οι ανάγκες των μαθητών ώστε να καλυφθούν με επιπλέον μαθήματα.</p>
                <p>&bull; ψυχολογική προετοιμασία για τις εξετάσεις.</p>
                <p>&bull; ολοκληρωμένη προετοιμασία για τα θέματα των εξετάσεων.</p>
              <div className="Proetimasia__container-h">
                <h2>ΤΟ ΘΕΡΙΝΟ ΠΡΟΓΡΑΜΜΑ ΠΡΟΕΤΟΙΜΑΣΙΑΣ ΠΕΡΙΛΑΜΒΑΝΕΙ:</h2>
                <div className="Proetimasia__container-p">
                  <p>Μαθήματα διάρκειας 6 εβδομάδων.</p>
                  <p> Οι 4 πρώτες εβδομάδες ξεκινούν στις 14 Ιουνίου και ολοκληρώνονται στις 18 Ιουλίου. Η 5η και η 6η εβδομάδα των θερινών μαθημάτων πραγματοποιούνται από 18 έως 31 Αυγούστου.</p>
                </div>
              </div>
              </div>
              <div className="Proetimasia__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>16 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΣΠΟΥΔΩΝ ΟΙΚΟΝΟΜΙΑΣ & ΠΛΗΡΟΦΟΡΙΚΗΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΟΙΚΟΝΟΜΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Α.Ε.Π.Π.</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΣΠΟΥΔΩΝ ΥΓΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΒΙΟΛΟΓΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΑΝΘΡΩΠΙΣΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΑΡΧΑΙΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΛΑΤΙΝΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΙΣΤΟΡΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="Proetimasia__side-Containers">
              <div className="Proetimasia__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
              <div className="anodos__proetimasia-email-container">
              <form className="anodos__proetimasia-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__proetimasia-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__proetimasia-email-container_label">Όνομα</label>
                <input className="anodos__proetimasia-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__proetimasia-email-container_label">Email</label>
                <input className="anodos__proetimasia-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__proetimasia-email-container_label">Μήνυμα</label>
                <textarea className="anodos__proetimasia-email-container_input-message" name="message" required/>
                <input className="anodos__proetimasia-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__proetimasia-email-container_popup anodos__proetimasia-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__proetimasia-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__proetimasia-email-container_popup anodos__proetimasia-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__proetimasia-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Proetimasia-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Proetimasia-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Proetimasia-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Proetimasia-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Proetimasia-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Proetimasia