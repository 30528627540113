import React from 'react';

import { Footer, Header, Main } from '../../containers';
import { Slider, Navbar, Links} from '../../components';
import './home.css';

const App = () => {
  return (
    <div className="App">
        <div className="gradient__bg">
          <Navbar />
          <Slider />
          <Header />
        </div>
        <Main />
        <Links />
        <Footer />
    </div>
  )
}

export default App