import React from "react";

export const CarouselItem = ({ item, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      <div></div>
      <img className="carousel-img" src={item.icon.default} alt=""/>
        <div className="content">
          <div className="carousel-item-text">{item.description}</div>
          <div className="carousel-item-title">{item.title}</div>
        </div>
    </div>
  );
};