import React from 'react';
import logo from '../../assets/logo transparent 2.png';
import './navbar.css';

const Menu = () => (
  <> 
  <nav class="menu">
    <ol>
      <li class="menu-item"><a href="/">Αρχική</a></li>
      <li class="menu-item">
        <a href="/Mathimata/">Οδηγός Σπουδών</a>
        <ol class="sub-menu">
          <li class="menu-item"><a href="/Mathimata/A-Lykeiou/">Α΄ Λυκείου</a></li>
          <li class="menu-item"><a href="/Mathimata/B-Lykeiou/">Β΄ Λυκείου</a></li>
          <li class="menu-item"><a href="/Mathimata/G-Lykeiou/">Γ΄ Λυκείου</a></li>
          <li class="menu-item"><a href="/Mathimata/Gymnasio/">Γυμνάσιο</a></li>
          <li class="menu-item"><a href="/Mathimata/EPAL/">ΕΠΑΛ</a></li>
          <li class="menu-item"><a href="/Mathimata/Proetimasia/">Προετοιμασία Γ' Λυκειου</a></li>
        </ol>
      </li>
      <li class="menu-item">
        <a href="/Frontistirio/">Το φροντιστήριο</a>
        <ol class="sub-menu">
          <li class="menu-item"><a href="/Frontistirio/Mathodos-Ekpaideusis/">Μέθοδος εκπαίδευσης</a></li>
          <li class="menu-item"><a href="/Frontistirio/Oi-Xwroi-Mas/">Οι χώροι μας</a></li>
          <li class="menu-item"><a href="/Frontistirio/Oi-Ekdolwseis-Mas/">Οι εκδηλώσεις μας</a></li>
          <li class="menu-item"><a href="/Frontistirio/Paroxes-Ypiresiwn/">Παροχές υπηρεσιών</a></li>
        </ol>
      </li>
      <li class="menu-item">
        <a href="/PoioiEimaste/">Ποιοί είμαστε</a>
        <ol class="sub-menu">
          <li class="menu-item"><a href="/PoioiEimaste/I-Filosofia-Mas/">Η φιλοσοφία μας</a></li>
          <li class="menu-item"><a href="/PoioiEimaste/I-Leitourgia-Mas/">Η λειτουργία μας</a></li>
          <li class="menu-item"><a href="/PoioiEimaste/I-Istoria-Mas/">Η ιστορία μας</a></li>
          <li class="menu-item"><a href="/PoioiEimaste/Oi-Kathigites-Mas/">Οι καθηγητές μας</a></li>
          <li class="menu-item"><a href="/PoioiEimaste/Oi-Mathites-Mas/">Οι μαθητές μας</a></li>
        </ol>
      </li>
      <li class="menu-item"><a href="/Contact/">Επικοινωνία</a></li>
    </ol>
  </nav>
  </>
)

const Navbar = () => {
  return (
    <div className="anodos__navbar">
        <div className="anodos__navbar-links">
            <div className="anodos__navbar-links_logo">
              <a href='/'>
                <img src={logo} alt="logo"></img>
              </a>
            </div>
            <Menu />
        </div>
    </div>
  )
}

export default Navbar