import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Oi-Kathigites-Mas.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';


const Kathigites = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="Kathigites">
        <div className="gradient__bg">
          <Navbar className="navbar"/>
        </div>
        <div className="Kathigites__main-container">
          <div className="Kathigites__container">
            <div className="Kathigites__container-h">
              <h1>Οι Καθηγητές μας</h1>
            </div>
            <div className="Kathigites__container-p">
              <p>Το φροντιστήριο προσφέρει σημαντικά εφόδια, αφού διαθέτει πιστοποιημένες εκπαιδευτικές δομές υψηλών προδιαγραφών (κατάλληλες αίθουσες διδασκαλίας, διαδραστικούς πίνακες, αίθουσες πολλαπλών χρήσεων, σύγχρονες υποδομές, πληροφοριακά συστήματα εκπαίδευσης κλπ). Το ανθρώπινο δυναμικό του αποτελείται από επιστήμονες µε μεγάλη εμπειρία, εξειδίκευση, κατάρτιση και, κυρίως, µε εκπαιδευτική τεχνογνωσία στο σχεδιασμό και την υλοποίηση προγραμμάτων σπουδών. Κατέχει καλά την διδακτική τέχνη, που σημαίνει αγάπη για τον μαθητή, γνώση των ιδιαιτεροτήτων του και σεβασμό της προσωπικότητας του.</p>
              <p className="Kathigites-p__right">ΚΑΡΑΒΑΣΙΛΗ ΒΑΪΑ – ΜΑΘΗΜΑΤΙΚΟΣ
                  <p>Είμαι απόφοιτος του Μαθηματικού τμήματος των θετικών σπουδών του πανεπιστημίου Ιωαννίνων. Στα 25 χρόνια φροντιστηριακής εκπαίδευσης έχω εντρυφήσει με υπομονή, μελέτη, μεθοδικότητα σε όλους τους κλάδους των μαθηματικών που αφορούν την δευτεροβάθμια εκπαίδευση και με οδήγησαν στη συγγραφή μαθηματικών σημειώσεων. Στόχος τους είναι η ευόδωση των προσπαθειών των μαθητών μου.</p>
                  <p>Κύριος σκοπός μου δεν είναι μόνο η επιτυχία των μαθητών στο σχολείο και τις εξετάσεις τους αλλά και η συνειδητοποίηση της αξίας της μαθηματικής σκέψης σε όλους τους τομείς της ζωής τους.</p> 
                  <p>Μαζί με άξιους, καταρτισμένους και συνεργάσιμους εκπαιδευτικούς, σε έναν χώρο λειτουργικό και όμορφο χαράσσουμε τον δρόμο για την πραγμάτωση των ονείρων και των στόχων των μαθητών μας, άλλωστε «Η εκπαίδευση είναι δύναμη που γιατρεύει την ψυχή». Πλάτωνας, Έλληνας Φιλόσοφος (427-347 π.Χ.)</p>
              </p>
              <p className="Kathigites-p__left">ΑΘΑΝΑΣΙΟΣ ΜΠΑΝΤΕΣ – ΦΙΛΟΛΟΓΟΣ
                <p>Αποφοίτησα από τη Φιλοσοφική Σχολή των Ιωαννίνων και συγκεκριμένα από το τμήμα του Φιλοσοφικού Παιδαγωγικού Ψυχολογικού το 1998. Από τότε βρίσκομαι στην ιδιωτική εκπαίδευση έχοντας ως κύριο στόχο να μεταδώσω στους μαθητές την αγάπη για τα φιλολογικά μαθήματα.</p>
                <p>Η κατανόηση της αξίας του ορθού λόγου, η διαμόρφωση κριτικής σκέψης, η συγκρότηση εκφραστικής επάρκειας και η καλλιέργεια της αντίληψης ότι η γνώση είναι πηγή χαράς συνιστούν εφόδιο για το σύνολο της ζωής διαμορφώνοντας την ποιότητα των καθημερινών ενασχολήσεων. Και οι ποιοτικές ασχολίες καταδεικνύουν και τους ποιοτικούς ανθρώπους, όπως τουλάχιστον διδάσκει η αρχαία ελληνική φιλοσοφία.</p>
                <p>Όμως, η συνείδηση ότι η γνώση, ως άντληση ευχαρίστησης, αποτελεί διαρκή αναζήτηση καθιστά τις σχέσεις αμφίδρομες. Ο δάσκαλος μαθαίνει πολλά από τους μαθητές του. Από αυτή την άποψη, μαζί με τους μαθητές βρίσκομαι κι εγώ σε κατάσταση διαρκούς μαθητείας. Είναι η αιώνια μαθητεία του δασκάλου. Κι αυτή ακριβώς είναι η ευτυχία του επαγγέλματός μου.</p>
                <p>Στο φροντιστήριο Άνοδος όλο το εκπαιδευτικό προσωπικό κινείται προς αυτή την κατεύθυνση της διαρκούς αλληλεπίδρασης με τον μαθητή. Η επίγνωση ότι ο δάσκαλος αλληλοσυμπληρώνεται με τον μαθητή δεν αποτελεί μόνο την προϋπόθεση μιας ουσιαστικής εποικοδομητικής σχέσης αλλά και το κλειδί της εκπαιδευτικής επιτυχίας. «Γηράσκω δ’ αιεί πολλά διδασκόμενος»  Σωκράτης, Έλληνας Φιλόσοφος </p>
              </p>
              <p className="Kathigites-p__right">ΧΑΤΖΗΑΓΓΕΛΙΔΗΣ ΑΝΕΣΤΗΣ – ΦΥΣΙΚΟΣ
                <p>Είμαι απόφοιτος του τμήματος Φυσικής του Αριστοτελείου Πανεπιστημίου Θεσσαλονίκης από το 2008. Διδάσκω στην ιδιωτική εκπαίδευση από το 2009 σε μαθητές Γυμνασίου και Λυκείου τα μαθήματα της Φυσικής και Χημείας.</p>
                <p>Σκοπός μου μέσα από τη διδασκαλία είναι να εμπλουτίσω στους μαθητές την φυσική σκέψη και την ικανότητα επίλυσης προβλημάτων. Πιστεύοντας στις δυνατότητες κάθε παιδιού δρω ώστε να φτάσει στο καλύτερο αποτέλεσμα. </p>
                <p>Στο φροντιστήριο Άνοδος έχουμε μια δυνατή ομάδα με έμπειρους και ικανούς καθηγητές οι οποίοι συνεργάζονται συνεχώς με σκοπό να πετύχουν την μέγιστη εξέλιξη του μαθητή.</p>
                <p>«Εκπαίδευση είναι η βαθμιαία ανακάλυψη της άγνοιας μας» Will Durant.</p>
              </p>
              <p className="Kathigites-p__left">ΑΠΟΣΤΟΛΙΔΟΥ ΕΙΡΗΝΗ – ΠΛΗΡΟΦΟΡΙΚΟΣ
                <p>Είμαι απόφοιτος του Τμήματος ηλεκτρονικών Yπολογιστικών Συστημάτων του πανεπιστημίου Δυτικής Αττικής. Ο προγραμματισμός είναι η μεγάλη μου αγάπη και έπειτα από ειδικά σεμινάρια και επιμορφώσεις, διδάσκω το μάθημα του ΑΕΠΠ σε μαθητές τρίτης τάξης Γενικού Λυκείου, Προγραμματισμό σε μαθητές επαγγελματικού Λυκείου και ρομποτική σε παιδιά προσχολικής ηλικίας.</p>
                <p>Στόχος μου είναι να περάσω την αγάπη μου για τον προγραμματισμό στα παιδιά ώστε οι υπολογιστικές μηχανές να γίνουν εργαλεία χρήσιμα για τη μετέπειτα εξέλιξή τους. «Το μυαλό δεν είναι ένα δοχείο που πρέπει να γεμίσει αλλά μία φωτιά που πρέπει να ανάψει»  Πλούταρχος, Έλληνας Ιστορικός</p>
                <p>Στο φροντιστήριο Άνοδος η δημιουργία ψηφιακών αιθουσών, ειδικά εξοπλισμένες με τις πιο σύγχρονες τεχνολογίες που αφορούν την εκπαίδευση, είναι αυτό που σε συνδυασμό με τη διδασκαλία κάνει τη διαφορά και  οδηγεί τον μαθητή στην καλύτερη εμπέδωση της ύλης. </p>            
              </p>
              <p className="Kathigites-p__right">ΠΑΠΑΔΗΜΗΤΡΙΟΥ ΝΙΚΟΛΕΤΑ- ΟΙΚΟΝΟΜΟΛΟΓΟΣ
                <p>Είμαι απόφοιτος του τμήματος Οργάνωσης και Διοίκησης Επιχειρήσεων και κάτοχος Παιδαγωγικής και Διδακτικής επάρκειας από την σχολή Κοινωνικών Επιστημών του ΕΑΠ. Έχω παρακολουθήσει αρκετά επιμορφωτικά σεμινάρια σε θέματα εκπαίδευσης, όπως συμπερίληψη στην εκπαίδευση, κριτική παιδαγωγική, συμβουλευτική και επαγγελματικός προσανατολισμός, εκπαίδευση ενηλίκων. Επιπλέον, είμαι κάτοχος πιστοποιητικού εξειδικευμένης επιμόρφωσης από το ΠΑΜΑΚ στον τομέα: «Σχολική ψυχολογία». </p>
                <p>Έχοντας ως κίνητρο την αγάπη για την διδασκαλία και την μετάδοση γνώσεων, εντάχθηκα το 2020 στην φροντιστηριακή εκπαίδευση και βρίσκομαι συνεχώς δίπλα στους μαθητές μου, διδάσκοντας μαθήματα του Οικονομικού τομέα. Στόχος μου δεν είναι μόνο η επιτυχία των μαθητών στις πανελλαδικές εξετάσεις αλλά να εμπνεύσω τον μαθητή και να τον αφυπνίσω ώστε να φτάσει τον στόχο του. «Εκπαιδεύοντας το μυαλό, χωρίς να εκπαιδεύσουμε την καρδιά, δεν είναι καθόλου εκπαίδευση» Αριστοτέλης (384 – 322 π.Χ.),  Έλληνας φιλόσοφος</p>
                <p>Επέλεξα να είμαι μέλος του φροντιστηρίου Άνοδος λόγω της άψογης συνεργασίας μεταξύ του διδακτικού προσωπικού, των άριστων τεχνολογικών υποδομών, του προσεγμένου και ευχάριστου χώρου διδασκαλίας, των δράσεων που συμμετέχει το φροντιστήριο αλλά και της πραγμάτωσης ενός κοινού οράματος.</p>
              </p>
              <p className="Kathigites-p__left">ΚΑΡΑΜΠΕΛΙΑΣ ΠΑΝΑΓΙΩΤΗΣ- ΦΙΛΟΛΟΓΟΣ
                <p>Είμαι απόφοιτος του τμήματος Ιστορίας και Αρχαιολογίας της Φιλοσοφικής Σχολής του Αριστοτέλειου Πανεπιστημίου Θεσσαλονίκης καθώς και κάτοχος διπλώματος στην Διαπολιτισμική Εκπαίδευση από το Πανεπιστήμιο Μακεδονίας. Τα τελευταία χρόνια εργάζομαι στην ιδιωτική εκπαίδευση έχοντας ως βασικό στόχο να μεταδώσω στους μαθητές το ενδιαφέρον για τα φιλολογικά μαθήματα αλλά και γενικότερα την αγάπη για την έκφραση και την γλωσσά.</p>
                <p>Κατά τη διάρκεια των σπουδών μου ανέπτυξα ισχυρά θεμέλια στην κριτική σκέψη και την έρευνα, την οποία προσπαθώ να εφαρμόσω με επιτυχία σε φροντιστήρια μαθητών όλων των ηλικιών. Βρίσκω μεγάλη χαρά στο να βοηθώ τους μαθητές όχι μόνο να κατανοήσουν δύσκολες έννοιες αλλά και να αναπτύξουν μια βαθύτερη κατανόηση και εκτίμηση για την Φιλολογία. Η εμπειρία μου στην εκπαίδευση βελτίωσε την ικανότητά μου να προσαρμόζω το μάθημα σε διαφορετικά στυλ μάθησης, ώστε να διασφαλίζεται με κάθε τρόπο η μεταδοτικότητα και να διατηρώ ένα υποστηρικτικό περιβάλλον για τους μαθητές.</p>
                <p>Μέσω της άμεσης ενασχόλησης με τους μαθητές, επιτυγχάνεται η βοήθεια στο να ξεπεράσουν τις σχολικές προκλήσεις και να ενισχύσουν την πνευματική τους περιέργεια, γεγονός που ταυτόχρονα συμβάλλει σε σημαντικές βελτιώσεις στην απόδοση και την εμπιστοσύνη των μαθητών.</p>            
              </p>
              <p className="Kathigites-p__right">ΓΙΑΝΝΟΥΔΗ ΟΛΓΑ - ΒΙΟΛΟΓΟΣ
                <p>Ονομάζομαι Όλγα Γιαννούδη και είμαι απόφοιτη του τμήματος Μοριακής Βιολογίας και Γενετικής του ΔΠΘ. Διδάσκω Βιολογία σε μαθητές/τριες λυκείου και τα μαθήματα ειδικότητας Ανατομία και Υγιεινή σε μαθητές/τριες ΕΠΑΛ.</p>
                <p>Η Βιολογία είναι μια επιστήμη που εξελίσσεται καθημερινά και επηρεάζει πολλούς τομείς της ζωής, γι’ αυτό και στο φροντιστήριο μας πιστεύουμε ότι η διδασκαλία του μαθήματος θα πρέπει να εξελίσσεται ανάλογα και να εμπλουτίζεται με νέες μεθόδους και εκπαιδευτικό υλικό.</p>
                <p>Όλα τα μέλη της καθηγητικής ομάδας έχουμε ως πρώτο μέλημα να αναπτύξουμε σχέσεις επικοινωνίας και εμπιστοσύνης με τους μαθητές και τις μαθήτριες μας και στη συνέχεια να βοηθήσουμε τον καθένα και την καθεμία ξεχωριστά ώστε να εξελιχθεί και να πετύχει τους στόχους του.</p>
                <p>«Η διδασκαλία είναι κάτι περισσότερο από το να διανέμεις τη γνώση, είναι να εμπνέεις την αλλαγή.» William A. Ward</p>
              </p>
              <p className="Kathigites-p__left">ΕΥΑΓΓΕΛΙΑ ΤΣΑΚΙΡΙΔΟΥ - ΦΙΛΟΛΟΓΟΣ
                <p>Είμαι απόφοιτος του τμήματος Ιστορίας και Εθνολογίας του Δημοκρίτειο Πανεπιστημίου Θράκης. Από την πρώτη μέρα της αποφοίτησης μου, έως και σήμερα έχω αφιερωθεί στην ιδιωτική εκπαίδευση με κύριο στόχο την προσωπική μου εξέλιξη και μετέπειτα την διάδοση των γνώσεων μου στους μαθητές.</p>
                <p>Απώτερος στόχος μου είναι τα παιδιά να αγαπήσουν τα μαθήματα του είδους, νιώθοντας ότι αυτό που κάνουν δεν είναι απλά μια «αγγαρεία» της καθημερινότητας. Με αγάπη, υπομονή και ενσυναίσθηση, προσπαθώ καθημερινά για το καλύτερο αποτέλεσμα. Άλλωστε αυτό είναι και το μέλημα του δασκάλου, να είναι πρώτα άνθρωπος και ύστερα δάσκαλος με τους μαθητές του.</p>          
              </p>
              <p className="Kathigites-p__right">ΖΕΛΚΑΣ ΔΗΜΗΤΡΗΣ - ΜΑΘΗΜΑΤΙΚΟΣ
                <p>Απόφοιτος του Τμήματος Μαθηματικών του Πανεπιστημίου Αιγαίου στη Σάμο, όπου ολοκλήρωσα το πτυχίο μου το 2021 και το μεταπτυχιακό μου στις Σπουδές στα Μαθηματικά το 2023. Έχω εμπειρία στην εκπαίδευση, έχοντας διδάξει ως μέρος της πρακτικής μου άσκησης σε λύκειο, ενώ έχω παρακολουθήσει σεμινάρια μαθηματικών και έχω διακριθεί σε διαγωνισμό της Ελληνικής Μαθηματικής Εταιρείας (Θαλής).</p>
                <p>Ο κύριος σκοπός της εκπαίδευσης είναι η ανάπτυξη των γνώσεων, δεξιοτήτων και αξιών που οδηγούν τον μαθητή σε μια διαρκή ΑΝΟΔΟ, όχι μόνο στην ακαδημαϊκή του πορεία αλλά και στην προσωπική του εξέλιξη.</p>
                <p>«Ο μαθηματικός είναι ένας τυφλός σε ένα σκοτεινό δωμάτιο που ψάχνει για μια μαύρη γάτα που δεν είναι εκεί». Κάρολος Δαρβίνος, Φυσιολόγος</p>
              </p>
            </div>
          </div>
          <div className="Kathigites__side-Containers">
            <div className="Kathigites__side-Container1">
              <h1>Χρήσιμες Σελίδες</h1>
              <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="anodos__alykeiou-email-container">
              <form className="anodos__alykeiou-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__alykeiou-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__alykeiou-email-container_label">Όνομα</label>
                <input className="anodos__alykeiou-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__alykeiou-email-container_label">Email</label>
                <input className="anodos__alykeiou-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__alykeiou-email-container_label">Μήνυμα</label>
                <textarea className="anodos__alykeiou-email-container_input-message" name="message" required/>
                <input className="anodos__alykeiou-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Alykeiou-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Kathigites