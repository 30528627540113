import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import PoioiEimaste from './pages/PoioiEimaste/PoioiEimaste';
import Filosofia from './pages/PoioiEimaste/Sub-pages/I-Filosofia-Mas/I-Filosofia-Mas';
import Istoria from './pages/PoioiEimaste/Sub-pages/I-Istoria-Mas/I-Istoria-Mas';
import Leitourgia from './pages/PoioiEimaste/Sub-pages/I-Leitourgeia-mas/I-Leitourgia-Mas';
import Kathigites from './pages/PoioiEimaste/Sub-pages/Oi-Kathigites-Mas/Oi-Kathigites-Mas';
import Mathites from './pages/PoioiEimaste/Sub-pages/Oi-Mathites-Mas/Oi-Mathites-Mas';
import Frontistirio from './pages/To-Frontistirio/To-Frontistirio';
import Methodos from './pages/To-Frontistirio/Sub-pages/Methodos-Ekpaideusis/Methodos-Ekpaideusis';
import Ekdoloseis from './pages/To-Frontistirio/Sub-pages/Oi-Ekdiloseis-Mas/Oi-Ekdoloseis-Mas';
import Xwroi from './pages/To-Frontistirio/Sub-pages/Oi-Xwroi-Mas/Oi-Xwroi-Mas';
import Paroxes from './pages/To-Frontistirio/Sub-pages/Paroxes-Ypiresiwn/Paroxes-Ypiresiwn';
import Mathimata from './pages/Mathimata/Mathimata';
import Alykeiou from './pages/Mathimata/Sub-pages/A-Lykeiou/A-Lykeiou';
import Blykeiou from './pages/Mathimata/Sub-pages/B-Lykeiou/B-Lykeiou';
import Glykeiou from './pages/Mathimata/Sub-pages/G-Lykeiou/G-Lykeiou';
import Gymnasio from './pages/Mathimata/Sub-pages/Gymnasio/Gymnasio';
import EPAL from './pages/Mathimata/Sub-pages/EPAL/EPAL';
import Proetimasia from './pages/Mathimata/Sub-pages/Proetimasia-G-Lykeiou/Proetimasia-G-Lykeiou';
import Themata from './pages/Themata/Themata';
import Ypologismos from './pages/YpologismosMoriwn/YpologismosMoriwn'
import Trapeza from './pages/Trapeza/Trapeza'
import Spoudes from './pages/Spoudes&Epaggelmata/Spoudes&Epaggelmata'
import ELearning from './pages/E-learning/E-learning';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact/" element={<Contact />} />
        <Route exact path="/PoioiEimaste/" element={<PoioiEimaste />} />
          <Route exact path="/Poioieimaste/I-Filosofia-Mas/" element={<Filosofia />} />
          <Route exact path="/Poioieimaste/I-Istoria-Mas/" element={<Istoria />} />
          <Route exact path="/Poioieimaste/I-Leitourgia-Mas/" element={<Leitourgia />} />
          <Route exact path="/Poioieimaste/Oi-Kathigites-Mas/" element={<Kathigites />} />
          <Route exact path="/Poioieimaste/Oi-Mathites-Mas/" element={<Mathites />} />
        <Route exact path="/Frontistirio/" element={<Frontistirio />} />
          <Route exact path="/Frontistirio/Mathodos-Ekpaideusis/" element={<Methodos />} />
          <Route exact path="/Frontistirio/Oi-Ekdolwseis-Mas/" element={<Ekdoloseis />} />
          <Route exact path="/Frontistirio/Oi-Xwroi-Mas/" element={<Xwroi />} />
          <Route exact path="/Frontistirio/Paroxes-Ypiresiwn/" element={<Paroxes />} />
        <Route exact path="/Mathimata/" element={<Mathimata />} />
          <Route exact path="/Mathimata/A-Lykeiou/" element={<Alykeiou />} />
          <Route exact path="/Mathimata/B-Lykeiou/" element={<Blykeiou />} />
          <Route exact path="/Mathimata/G-Lykeiou/" element={<Glykeiou />} />
          <Route exact path="/Mathimata/Gymnasio/" element={<Gymnasio />} />
          <Route exact path="/Mathimata/EPAL/" element={<EPAL />} />
          <Route exact path="/Mathimata/Proetimasia/" element={<Proetimasia />} />
        <Route exact path="/Themata/" element={<Themata />} />
        <Route exact path="/Ypologismos/" element={<Ypologismos />} />
        <Route exact path="/Trapeza/" element={<Trapeza />} />
        <Route exact path="/Spoudes/" element={<Spoudes />} />
        <Route exact path="/eLearning/" element={<ELearning />} />
      </Routes>
    </Router>
  );
}

export default App;