import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Gymnasio.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';

const Gymnasio = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="Gymnasio">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="Gymnasio__main-container">
          <div className="Gymnasio__container">
            <div className="Gymnasio__container-h">
              <h1>ΓΥΜΝΑΣΙΟ</h1>
              <div className="Gymnasio__container-p">
                <p>Ο μαθητής του Γυμνασίου είναι πολύ σημαντικό να συμμετέχει σε ένα φροντιστηριακό πρόγραμμα σπουδών όπου είναι ικανό να διακρίνει τις κλίσεις του και τα ενδιαφέροντά του. Έτσι, θα προετοιμαστεί συστηματικά και μεθοδικά αναπτύσσοντας δυναμικά τις δικές του δεξιότητες γιατί:</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Συμμετέχει σε ολιγομελή τμήματα με βάση το γνωστικό του επίπεδο</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Μαθαίνει να μελετά μόνος του</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Τα μαθήματα διδάσκονται από εξειδικευμένους καθηγητές κάθε ειδικότητας</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Διδάσκονται όλα τα μαθήματα του Γυμνασίου</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Χτίζει τις βάσεις που απαιτούνται για τις τάξεις του Λυκείου</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Αποκτά αυτενέργεια και αυτοπεποίθηση</p>
                <p>Η σωστή προετοιμασία είναι το κλειδί της επιτυχίας!</p>
              </div>
              <div className="Gymnasio__container-h">
                <h2>Ωρολόγιο πρόγραμμα του Γυμνασίου:</h2>
              </div>
              <div className="Gymnasio__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">Γ΄ ΓΥΜΝΑΣΙΟΥ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td></td>
                        <td>ΜΑΘΗΜΑΤΑ</td>
                        <td>1-3 ΑΤΟΜΑ</td>
                        <td>3-6 ΑΤΟΜΑ</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Μαθηματικά</td>
                        <td>2 ώρες /εβδ</td>
                        <td>3 ώρες /εβδ</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Έκθεση</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Φυσική</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Αρχαία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Χημεία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Βιολογία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">Β΄ ΓΥΜΝΑΣΙΟΥ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td></td>
                        <td>ΜΑΘΗΜΑΤΑ</td>
                        <td>1-3 ΑΤΟΜΑ</td>
                        <td>3-6 ΑΤΟΜΑ</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Μαθηματικά</td>
                        <td>2 ώρες /εβδ</td>
                        <td>3 ώρες /εβδ</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Έκθεση</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Φυσική</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Αρχαία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Χημεία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Βιολογία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">Α΄ ΓΥΜΝΑΣΙΟΥ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td></td>
                        <td>ΜΑΘΗΜΑΤΑ</td>
                        <td>1-3 ΑΤΟΜΑ</td>
                        <td>3-6 ΑΤΟΜΑ</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Μαθηματικά</td>
                        <td>2 ώρες /εβδ</td>
                        <td>3 ώρες /εβδ</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Έκθεση</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Φυσική</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Αρχαία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="Gymnasio__side-Containers">
              <div className="Gymnasio__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
              <div className="anodos__gymnasio-email-container">
              <form className="anodos__gymnasio-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__gymnasio-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__gymnasio-email-container_label">Όνομα</label>
                <input className="anodos__gymnasio-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__gymnasio-email-container_label">Email</label>
                <input className="anodos__gymnasio-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__gymnasio-email-container_label">Μήνυμα</label>
                <textarea className="anodos__gymnasio-email-container_input-message" name="message" required/>
                <input className="anodos__gymnasio-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__gymnasio-email-container_popup anodos__gymnasio-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__gymnasio-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__gymnasio-email-container_popup anodos__gymnasio-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__gymnasio-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Gymnasio-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Gymnasio-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Gymnasio-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Gymnasio-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Gymnasio-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Gymnasio