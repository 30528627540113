import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './I-Istoria-Mas.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';

const Istoria = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };
  return (
    <div className="Istoria">
        <div className="gradient__bg">
          <Navbar className="navbar"/>
        </div>
        <div className="Istoria__main-container">
          <div className="Istoria__container">
            <div className="Istoria__container-h">
              <h1>Η Ιστορία μας</h1>
            </div>
            <div className="Istoria__container-p">
              <p>&nbsp;&nbsp;&nbsp;Η επιχείρηση συστάθηκε το 1999 για να προσφέρει φροντιστηριακά μαθήματα υψηλού επίπεδου στην ευρύτερη περιοχή του Δήμου Σιδηροκάστρου. Από τον πρώτο καιρό έγινε φανερή η δυναμική της που μεταφράστηκε σε αυξημένους κύκλους εργασιών, αλλά και οι ευθύνες της επιχείρησης απέναντι στους πελάτες της. Σε αυτή την πορεία αναπτύχθηκαν και εισήχθησαν στην παραγωγική διαδικασία νέα διαφοροποιημένα προϊόντα και υπηρεσίες που εξυπηρέτησαν την τμηματοποίηση της αγοράς δραστηριοποίησης της.</p>
              <p>&nbsp;&nbsp;&nbsp;Τα σημαντικότερα γεγονότα που καθόρισαν την πορεία της ήταν οι δύο αλλαγές της έδρας και των χώρων λειτουργίας της εταιρείας το 2001 και το 2008, που επέτρεψαν στην εταιρεία να αναπτυχθεί περαιτέρω βελτιώνοντας την παραγωγική της ικανότητα. Ταυτόχρονα δόθηκε η δυνατότητα εκσυγχρονισμού της παραγωγικής διαδικασίας και της προσθήκης των νέων προϊόντων και υπηρεσιών.</p>
            </div>
          </div>
          <div className="Istoria__side-Containers">
              <div className="Istoria__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
              <div className="anodos__alykeiou-email-container">
              <form className="anodos__alykeiou-email-container_form" ref={form} onSubmit={sendEmail}>
                <h1 className="anodos__alykeiou-email-container_h1">Φόρμα Επικοινωνίας</h1>
                <label className="anodos__alykeiou-email-container_label">Όνομα</label>
                <input className="anodos__alykeiou-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__alykeiou-email-container_label">Email</label>
                <input className="anodos__alykeiou-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__alykeiou-email-container_label">Μήνυμα</label>
                <textarea className="anodos__alykeiou-email-container_input-message" name="message" required/>
                <input className="anodos__alykeiou-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Alykeiou-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alykeiou-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
            </div>
            </div>
        <Footer />
    </div>
  )
}

export default Istoria