import React from 'react';
import './footer.css';
import logo from '../../assets/logo2.png';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import tiktok from '../../assets/tiktok.png';

const Footer = () => {
  return (
    <div className="anodos__footer section__padding">
      <div className="anodos__footer-links">
        <div className="anodos__footer-links_logo">
          <img src={logo} alt="logo"></img>
        </div>
        <div className="anodos__footer-links_address">
        <p>Διέυθυνση : <a href="https://www.google.com/maps/place/%CE%A6%CF%81%CE%BF%CE%BD%CF%84%CE%B9%CF%83%CF%84%CE%AE%CF%81%CE%B9%CE%BF+%CE%9C.%CE%95+%CE%86%CE%BD%CE%BF%CE%B4%CE%BF%CF%82/@41.2368355,23.3897812,17z/data=!4m14!1m7!3m6!1s0x14a9796d891a87fb:0x5f99a1b6d11c819d!2zzqbPgc6_zr3PhM65z4PPhM6uz4HOuc6_IM6cLs6VIM6Gzr3Ov860zr_Pgg!8m2!3d41.2368355!4d23.3923561!16s%2Fg%2F11hyzffgbm!3m5!1s0x14a9796d891a87fb:0x5f99a1b6d11c819d!8m2!3d41.2368355!4d23.3923561!16s%2Fg%2F11hyzffgbm?entry=ttu" style={{ color: '#05B2DC', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">Ρούπελ 6, Σιδηρόκαστρο 62300</a></p>
          <br/>
          <p>Τηλ : <a href="tel:+302323025175" style={{ color: '#05B2DC', textDecoration: 'underline' }}>2323025175</a></p>
          <br/>
          <p>Email : <a href="mailto:anodos.fr6@gmail.com" style={{ color: '#05B2DC', textDecoration: 'underline' }}>anodos.fr6@gmail.com</a></p>
        </div>
        <div className="anodos__footer-links_social">
          <a href='https://www.facebook.com/ANODOS.SID/' target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="facebook"></img>
          </a>
          <br/>
          <a href='https://www.instagram.com/frontistirioanodos/'  target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="instagram"></img>
          </a>
          <br/>
          <a href='https://www.facebook.com/ANODOS.SID/' target="_blank" rel="noopener noreferrer">
            <img src={tiktok} alt="tiktok"></img>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer